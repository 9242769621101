<template>
  <section class="section section-margin-top">
    <div class="container">
      <h1 class="section-title text-link">Вопросы и ответы</h1>
      <details>
        <summary class="is-size-4"><b>Что такое дизайн-проект?</b></summary>
        <p class="has-background-primary has-text-white">
          Дизайн-проект это прорисовка вашей идеи в специальной программе. Он
          включает в себя проектирование мебели (материалы, комплектующие, цвет,
          наполнение), размещение мебели в пространстве комнаты, и расчет
          стоимости на изготовление.
        </p>
      </details>
      <details>
        <summary class="is-size-4">
          <b>Сколько будет стоить мой заказ?</b>
        </summary>
        <p class="has-background-primary has-text-white">
          Стоимость вашего заказа рассчитывается индивидуально. Основой для
          расчеты цены является ваш проект. Влияние на цену оказывают: сложность
          дизайна мебели, выбранные материалы и фурнитура. При разработке
          проекта вы можете обозначить дизайнеру планируемый бюджет и мы
          создадим мебель, не превышая желаемую стоимость.
        </p>
      </details>
      <details>
        <summary class="is-size-4">
          <b>Какой срок изготовления мебели?</b>
        </summary>
        <p class="has-background-primary has-text-white">
          Зависит от сложности проекта и уникальности используемых материалов и
          комплектующих.
        </p>
      </details>
      <details>
        <summary class="is-size-4">
          <b
            >Можно ли внести изменения в изделие после утверждения
            дизайн-проекта?</b
          >
        </summary>
        <p class="has-background-primary has-text-white">
          После оформления заказа в течении 3 дней возможно внести изменения и
          доработки.
        </p>
      </details>
      <details>
        <summary class="is-size-4">
          <b>Почему важно доверить сборку мебели нашей компании?</b>
        </summary>
        <p class="has-background-primary has-text-white">
          Каждый заказ является индивидуальным и единственным в своей природе,
          поэтому инструкция по сборке может носить только рекомендательный
          характер. Мы соберём мебель быстро и профессионально. И вам не
          придётся переживать за качество сборки, которое напрямую оказывает
          влияние на срок эксплуатации мебели.
        </p>
      </details>
    </div>
  </section>
</template>
