<template>
  <section class="section section-margin-top">
    <div class="container">
      <div class="has-text-centered">
        <h1 class="section-title text-link">Почему нам доверяют</h1>
      </div>
      <div class="columns is-multiline">
        <div class="column">
          <div class="card hover-shadow shadow card-full">
            <div class="card-content has-text-centered text-primary">
              <h1 class="mb-20"><font-awesome-icon icon="pen-ruler" /></h1>
              <h3 class="mb-20">Профессиональный дизайн</h3>
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="card has-background-primary hover-shadow shadow card-full"
          >
            <div class="card-content has-text-centered has-text-white">
              <h1 class="mb-20"><font-awesome-icon icon="chair" /></h1>
              <h3 class="mb-20">
                Фабричное качество с индивидуальным решением
              </h3>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card hover-shadow shadow card-full">
            <div class="card-content has-text-centered text-primary">
              <h1 class="mb-20"><font-awesome-icon icon="calendar-days" /></h1>
              <h3 class="mb-20">Точные сроки производства</h3>
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="card has-background-primary hover-shadow shadow card-full"
          >
            <div class="card-content has-text-centered has-text-white">
              <h1 class="mb-20"><font-awesome-icon icon="circle-check" /></h1>
              <h3 class="mb-20">Гарантия на мебель</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
