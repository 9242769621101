<template>
  <section class="section section-margin-top">
    <div class="container">
      <div class="has-text-centered">
        <h1 class="section-title text-link">Этапы работы</h1>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6-desktop is-6-tablet">
          <div class="card shadow card-full">
            <div class="card-content has-background-white text-primary">
              <h2>
                <span class="has-background-primary has-text-white point-radius"
                  >①</span
                >
                Создание проекта
              </h2>
              <h5>
                <span
                  >Вместе с вами сформулируем идею, которая понравится именно
                  вам. Ответим на все ваши вопросы, подберем материалы и цвета,
                  а после создадим эскиз, учитывающий каждую деталь и все
                  особенности вашего дома.</span
                >
              </h5>
            </div>
          </div>
        </div>
        <div class="column is-6-desktop is-6-tablet">
          <div class="card shadow card-full">
            <div class="card-content has-background-white text-primary">
              <h2>
                <span class="has-background-primary has-text-white point-radius"
                  >②</span
                >
                Выезд на замер
              </h2>
              <h5>
                <span
                  >Точные замеры — гарантия безупречной установки мебели.
                  Сделаем точные замеры вашего пространства и учтем все
                  особенности помещения.</span
                >
              </h5>
            </div>
          </div>
        </div>
        <div class="column is-6-desktop is-6-tablet">
          <div class="card shadow card-full">
            <div class="card-content has-background-white text-primary">
              <h2>
                <span class="has-background-primary has-text-white point-radius"
                  >③</span
                >
                Доставка и сборка
              </h2>
              <h5>
                <span
                  >Бережно доставим, поднимем в квартиру и аккуратно соберем
                  мебель в удобное для вас время.</span
                >
              </h5>
            </div>
          </div>
        </div>
        <div class="column is-6-desktop is-6-tablet">
          <div class="card shadow card-full">
            <div class="card-content has-background-white text-primary">
              <h2>
                <span class="has-background-primary has-text-white point-radius"
                  >④</span
                >
                Гарантийное обслуживание
              </h2>
              <h5>
                <span
                  >Мы сможем помочь вам в любом вопросе по эксплуатации
                  мебели.</span
                >
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.point-radius {
  border-radius: 20px;
}
</style>
