<template>
  <footer class="footer has-text-white">
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-2-tablet">
            <p>
              <strong><a href="/" class="has-text-white">ОДИССЕЯ</a></strong>
            </p>
          </div>
          <div class="column is-8-tablet">
            <p class="is-size-7">
              Вся предоставленная на сайте информация не является публичной
              офертой, определяемой положениями Статьи 437(2) Гражданского
              кодекса РФ.
            </p>
          </div>
          <div class="column is-2-tablet has-text-right">
            <p>© 2024 <a href="https://vk.com/aapand" target="_blank" class="has-text-white">Pandapp</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
