<template>
  <section class="section section-margin-top">
    <div class="container">
      <div class="has-text-centered">
        <h1 class="section-title">Политика конфиденциальности</h1>
      </div>
      <pre class="is-common">
Политика конфиденциальности и защиты информации.

Оставляя данные на сайте, Вы соглашаетесь с Политикой конфиденциальности и защиты информации. 
Общие термины и определения
персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных); 
оператор - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными; 
обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных; 
автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники; 
распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц; 
предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
Защита данных 
Администрация сайта www.odyssey-mebel.ru (далее Сайт) не может передать или раскрыть информацию, предоставленную пользователем (далее Пользователь) при регистрации и использовании функций сайта третьим лицам, кроме случаев, описанных законодательством страны, на территории которой пользователь ведет свою деятельность. 
Получение персональной информации 
Для коммуникации на сайте пользователь обязан внести некоторую персональную информацию. Для проверки предоставленных данных, сайт оставляет за собой право потребовать доказательства идентичности в онлайн или офлайн режимах.
Оператор обрабатывает персональные данные исключительно в целях осуществления хозяйственной деятельности, направленной на извлечение прибыли (включая, но не ограничиваясь путем: розничной торговли товарами, в том числе, мебелью и аксессуарами). 
Оператор осуществляет обработку следующих персональных данных общей категории: имя, фамилия, номер мобильного телефона, адрес.
Использование персональной информации 
Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. 
Обработка осуществляется следующими способами: 
1. автоматизированная обработка персональных данных; 
2. неавтоматизированная обработка персональных данных; 
3. смешанная обработка персональных данных, 
в том числе, с передачей по внутренней сети компании и/или с передачей по сети Интернет.
Сайт использует личную информацию Пользователя для обслуживания и для улучшения качества предоставляемых услуг. 
Часть персональной информации может быть предоставлена банку или платежной системе, в случае, если предоставление этой информации обусловлено процедурой перевода средств платежной системе, услугами которой Пользователь желает воспользоваться. Сайт прилагает все усилия для сбережения в сохранности личных данных Пользователя. Личная информация может быть раскрыта в случаях, описанных законодательством, либо когда администрация сочтет подобные действия необходимыми для соблюдения юридической процедуры, судебного распоряжения или легального процесса необходимого для работы Пользователя с Сайтом. 
В других случаях, ни при каких условиях, информация, которую Пользователь передает Сайту, не будет раскрыта третьим лицам.
Коммуникация 
После того, как Пользователь оставил данные, он получает сообщение, подтверждающее его успешную регистрацию. Пользователь имеет право в любой момент прекратить получение информационных бюллетеней воспользовавшись соответствующим сервисом в Сайте.
Ссылки 
На сайте могут содержаться ссылки на другие сайты. Сайт не несет ответственности за содержание, качество и политику безопасности этих сайтов. Данное заявление о конфиденциальности относится только к информации, размещенной непосредственно на сайте. 
Безопасность 
Сайт обеспечивает безопасность учетной записи Пользователя от несанкционированного доступа. 

Уведомления об изменениях 
Сайт оставляет за собой право вносить изменения в Политику конфиденциальности без дополнительных уведомлений. Нововведения вступают в силу с момента их опубликования. Пользователи могут отслеживать изменения в Политике конфиденциальности самостоятельно.
</pre
      >
    </div>
  </section>
</template>
