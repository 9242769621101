<template>
  <section class="section section-margin-top">
    <div class="container">
      <h1 class="has-text-centered text-primary is-size-1">404</h1>
      <h2 class="subtitle has-text-centered text-primary">
        Кажется что-то пошло не так! Запрашиваемая старница не найдена...
      </h2>
      <div className="has-text-centered">
        <a href="/" className="button is-primary"> Перейти на главную </a>
      </div>
    </div>
  </section>
</template>
